import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
// React boostrap
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
//SEO Matter
import Seo from "../components/seo"
const IndexPage = () => (
  <div id="Index">
    <Seo
      title="Soportes Aparcabicicletas | Aparcabicicletas | Marquesinas para Bicicletas - BIKESAFE"
      description="Bike Safe son los proveedores oficiales de portabicicletas, racks para bicicletas y techos para bicicletas del Programa Barclays Cycle Superhighway de Transport for London, que cubre la totalidad de las cuatro rutas existentes."
    />
    <Layout>
      <Container fluid className="p-0">
        <Row className="mb-5">
          <Col className="text-center no-padding-or-margin">
            <div className="parallax-home"><div className="home-title"><h1 className="home-title-text">
            Especialistas</h1>
              <h1 className="home-title-text-under opacity">
              en estacionamiento de bicicletas</h1></div></div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="mt-4">
          <Col sm="12">
            <h1 className="uppercase text-center border-top-bottom">Aparcabicicletas</h1>
          </Col>
        </Row>
        <Row>
        <Col sm="12" lg="3" className="p-2">
            <Link to="/product/61">
              <StaticImage placeholder="blurred" className="fluid" src="../product/61/homepage-61.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Aparcabici de dos niveles</h3>
              <p>¡Ahorro de espacio, magnífica utilización y excelente relación calidad-precio! El rack para bicicletas de dos alturas es un sistema de aparcamiento de bicicletas innovador y muy intuitivo que le permite...</p>
              <p className="shop-now">Solicite una cotización</p>
            </Link>
          </Col>
          <Col sm="12" lg="3" className="p-2">
            <Link to="/product/230">
              <StaticImage placeholder="blurred" className="fluid" src="../product/230/homepage-230.jpg"></StaticImage>
              <h3 className="uppercase mt-3">BikeSafe Aparcabici de dos niveles</h3>
              <p>El portabicicletas de dos niveles BikeSafe es un sistema de estacionamiento de bicicletas pionero y fácil de usar, con un excelente almacenamiento de bicicletas que ahorra espacio, excelente facilidad de uso...</p>
              <p className="shop-now">Solicite una cotización</p>
            </Link>
          </Col>
          <Col sm="12" lg="3" className="p-2">
            <Link to="/product/80">
              <StaticImage placeholder="blurred" className="fluid" src="../product/80/homepage-80.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Aparcabici de pared independiente</h3>
              <p>Una variación de nuestro popular aparcabici de pared. Este modelo independiente permite que los usuarios saquen el máximo provecho del la superficie del suelo. La estructura se puede fijar con tornillos...</p>
              <p className="shop-now">Solicite una cotización</p>
            </Link>
          </Col>
          <Col sm="12" lg="3" className="p-2">
            <Link to="/product/88">
              <StaticImage placeholder="blurred" className="fluid" src="../product/88/homepage-88.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Aparcabici semivertical</h3>
              <p>Para aprovechar al máximo el espacio cuando escasea. El aparcabici semivertical se puede utilizar al interior o exterior. La rueda trasera se puede candar al rack con un candado tipo D o una cadena...</p>
              <p className="shop-now">Solicite una cotización</p>
            </Link>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="mt-4">
          <Col sm="12">
            <h1 className="uppercase text-center border-top-bottom">Soportes Aparcabicicletas</h1>
          </Col>
        </Row>
        <Row>
        <Col sm="12" lg="6" className="p-2">
            <Link to="/product/99">
              <StaticImage placeholder="blurred" className="fluid" src="./product/99/homepage-99.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Soportabici Salamanca tradicional galvanizado</h3>
              <p>El diseño clásico y popular es perfecto para aquellos que buscan una excelente relación calidad-precio, escaso mantenimiento y un...</p>
              <p className="shop-now">Solicite una cotización</p>
            </Link>
          </Col>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/100">
              <StaticImage placeholder="blurred" className="fluid" src="./product/100/homepage-100.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Soportabici Salamanca Tradicional</h3>
              <p>La forma permite que el cuadro y la rueda de la bicicleta se canden de forma segura al soporte. El diseño clásico y popular es...</p>
              <p className="shop-now">Solicite una cotización</p>
            </Link>
          </Col>
        </Row>
      </Container>
      <Container>
      <Row className="mt-4">
          <Col sm="12">
            <h1 className="uppercase text-center border-top-bottom">Marquesinas para Bicicletas</h1>
          </Col>
        </Row>
                <Row>
                <Col sm="12" lg="4" className="p-2">
                        <Link to="/product/233">
                            <StaticImage placeholder="blurred"  className="fluid" src="../product/233/homepage-233.jpg"></StaticImage>
                            <h3 className="uppercase mt-3">Marquesina Gijón</h3>
                            <p>Marquesina disponible en acabado galvanizado o revestimiento pulverizado en color RAL con soporte para bicicletas toastrack galvanizado. Techo y estructuras que...</p>
                            <p className="shop-now">Solicite una cotización</p>
                        </Link>
                    </Col>
                    <Col sm="12" lg="4" className="p-2">
                        <Link to="/product/38">
                            <StaticImage placeholder="blurred"  className="fluid" src="../product/38/homepage-38.jpg"></StaticImage>
                            <h3 className="uppercase mt-3">Marquesina Ark</h3>
                            <p>Diseñado para duplicar el número de bicicletas que puede guardar en un espacio relativamente pequeño, con dos filas de aparcamiento de bicicletas. Marquesina disponible...</p>
                            <p className="shop-now">Solicite una cotización</p>
                        </Link>
                    </Col>
                    <Col sm="12" lg="4" className="p-2">
                        <Link to="/product/22">
                            <StaticImage placeholder="blurred" className="fluid" src="../product/22/homepage-22.jpg"></StaticImage>
                            <h3 className="uppercase mt-3">Marquesina Bristol</h3>
                            <p>La marquesina viene equipado con las mejores y más versátiles características para adaptarse a cualquier entorno. Refugio de acero dulce con fijaciones de tornillos...</p>
                            <p className="shop-now">Solicite una cotización</p>
                        </Link>
                    </Col>
                </Row>
            </Container>
      <Container>
        <Row className="mt-4">
          <Col sm="12">
            <h1 className="uppercase text-center border-top-bottom">Especialistas en estacionamiento de bicicletas</h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="p-2">
            <p>Bike Safe son los proveedores oficiales de portabicicletas, racks para bicicletas y techos para bicicletas del Programa Barclays Cycle Superhighway de Transport for London, que cubre la totalidad de las cuatro rutas existentes.</p>
            <p>El programa Barclays Cycle Superhighways, provisto de nuestros techos para bicicletas, portabicicletas y racks para bicicletas, ofrece nuevas rutas para bicicletas que entran en el centro de Londres desde las afueras, con el fin de proporcionar a los ciclistas trayectos de entrada a la ciudad más seguros, rápidos y directos.</p>
            <p>Barclays Cycle Superhighways ofrecerá miles de nuevos espacios de estacionamiento de bicicletas y formación gratuita o subvencionada para trasladarse al trabajo en bicicleta, así como mejores instalaciones para ciclistas en el trabajo a través de Bike Safe, el especialista en estacionamiento de bicicletas del Reino Unido.</p>
          </Col>
        </Row>
      </Container>
    </Layout>
  </div>
)

export default IndexPage
